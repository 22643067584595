import React from "react"
import { Link } from "gatsby"
import Layout from "../components/global/Layout"

const Index = () => {
  return (
    <Layout
      metaTitle="404 - Noget gik galt"
      metaDescription="Prøv en af vores mange andre sider i stedet"
    >
      <div className="container my-xl">
        <section className="text-content">
          <h1>Noget gik galt - Desværre...</h1>
          <p>
            <Link to="/">Gå tilbage til forsiden</Link> eller prøv nogle af
            vores mest populære guides:
          </p>
          <p>
            <Link to="/badehoteller">- Badehoteller</Link>
          </p>
          <p>
            <Link to="/kroophold">- Kroophold</Link>
          </p>
          <p>
            <Link to="/gourmetophold">- Gourmetophold</Link>
          </p>
          <p>
            <Link to="/slotsophold">- Slotsophold</Link>
          </p>
          <p>
            <Link to="/Weekendophold">- Weekendophold</Link>
          </p>
          <p>
            <Link to="/wellness-spaophold">- Wellness- og spaophold</Link>
          </p>
        </section>
      </div>
    </Layout>
  )
}

export default Index
